<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-4">
        <div class="col-sm-4">

          <!-- status iddle prymary color 100 -->
          <Grazi type="btn_primary_100_xl" size="btn_56" text="Iddle" stats="Iddle" />
        </div>
        <div class="col-sm-4">
          <Grazi type="btn_primary_100_lg" size="btn_48" text="Iddle" stats="Iddle" />
        </div>
        <div class="col-sm-4">
          <Grazi type="btn_primary_100_md" size="btn_40" text="Iddle" stats="Iddle" />
        </div>
        <div class="col-sm-4">
          <Grazi type="btn_primary_100_sm" size="btn_32" text="Iddle" stats="Iddle" />
        </div>

        <!-- status hover prymary color 100 -->
        <div class="col-sm-4">
          <Grazi type="btn_primary_100_xl" size="btn_56" text="Hover" stats="Hover" />
        </div>
        <div class="col-sm-4">
          <Grazi type="btn_primary_100_lg" size="btn_48" text="Hover" stats="Hover" />
        </div>
        <div class="col-sm-4">
          <Grazi type="btn_primary_100_md" size="btn_40" text="Hover" stats="Hover" />
        </div>
        <div class="col-sm-4">
          <Grazi type="btn_primary_100_sm" size="btn_32" text="Hover" stats="Hover" />
        </div>

        <!-- status pressed prymary color 150 -->
        <div class="col-sm-4">
          <Grazi type="btn_primary_150_xl" size="btn_56" text="Pressed" stats="Pressed" />
        </div>
        <div class="col-sm-4">
          <Grazi type="btn_primary_150_lg" size="btn_48" text="Pressed" stats="Pressed" />
        </div>
        <div class="col-sm-4">
          <Grazi type="btn_primary_150_md" size="btn_40" text="Pressed" stats="Pressed" />
        </div>
        <div class="col-sm-4">
          <Grazi type="btn_primary_150_sm" size="btn_32" text="Pressed" stats="Pressed" />
        </div>

        <!-- status disabled grayscale 100 -->
        <div class="col-sm-4">
          <Grazi type="btn_grayscale_100_xl" size="btn_56" text="Disabled" stats="Disabled" />
        </div>
        <div class="col-sm-4">
          <Grazi type="btn_grayscale_100_lg" size="btn_48" text="Disabled" stats="Disabled" />
        </div>
        <div class="col-sm-4">
          <Grazi type="btn_grayscale_100_md" size="btn_40" text="Disabled" stats="Disabled" />
        </div>
        <div class="col-sm-4">
          <Grazi type="btn_grayscale_100_sm" size="btn_32" text="Disabled" stats="Disabled" />
        </div>

        <!-- status loading prymary color 100 -->
        <div class="col-sm-4">
          <Grazi type="btn_primary_100_xl" size="btn_56" text="Loading" stats="Loading" />
        </div>
        
        <div class="col-sm-4">
          <Grazi type="btn_primary_100_lg" size="btn_48" text="Loading" stats="Loading" />
        </div>
        
        <div class="col-sm-4">
          <Grazi type="btn_primary_100_md" size="btn_40" text="Loading" stats="Loading" />
        </div>
        
        <div class="col-sm-4">
          <Grazi type="btn_primary_100_sm" size="btn_32" text="Loading" stats="Loading" />
        </div>

        <div>
          <Grazi type="btn_primary_100_sm" size="btn_48" v-bind:icon_left="logo" />
        </div>

        <div>
          <Grazi type="btn_primary_100_sm" size="btn_48"  v-bind:icon_right="logo" />
        </div>

        <img class="plus-icon pointer"  src="@/assets/icons/download-simple.svg" alt="plus-icon">
        
      </div>
    </div>
  </div>






  <!-- <div class="col-sm-4 btn_primary_100_lg">
          <Grazi type="btn_primary_100_lg"  size="btn_56" text="Iddle" stats="Iddle"/>
          
      </div> -->

  <!-- <div class="col-sm-4">
    <button class="btn_primary_100_xl">
      <img class="plus-icon pointer" src="./icons/download-simple.svg" alt="plus-icon">
      <Grazi type="btn_primary_100_lg" size="btn_48" text="Iddle" stats="Iddle" />
        <span class="cria-contato pointer">Criar contato</span>
      </button>
  </div> -->


  <!-- <Grazi type="btn_primary_color" text="Hover" stats="Hover" />
      <Grazi type="btn_primary_color" text="Pressed" stats="Pressed" />
      <Grazi type="btn_primary_color" text="Disabled" stats="Disabled" />
      <Grazi type="btn_primary_100_xl" text="Loading" stats="Loading" />


      <Grazi type="btn_primary_color" />
      <div class="col-sm-4">
        <Grazi type="btn_primary_color" size="btn_32" />
      </div>
      <div class="col-sm-4">
        <Grazi type="btn_danger_color" size="btn_56" />
      </div> -->
  <!-- <div class="col-sm-4">
        <Grazi type="btn_primary_100_sm" size="btn_48" icon_left="ph-airplane" />
      </div> -->

</template>

<script>

import appConfig from "../../../app.config";
import Grazi from "@/components/Grazi.vue";
import logo from '@/assets/icons/download-simple.svg';

export default {
  components: {
    Grazi
  },
  props: {
    logo: {
      type: String,
      default: logo
    }
  },
  page: {
    title: "Page blank",
    meta: [{
      name: "description",
      content: appConfig.description,
    },],
  },
  data() {
    return {
      usuario: {
        Nome: null,
        status: false,
        message: null,
        hash: '',
      },
      alerta: {
        hidden: false
      }
    };
  },
  validations: {},
  computed: {},
  created() {
  },
  methods: {

  },
}
</script>
<style>

</style>