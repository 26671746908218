<template>
  <button :class="this.Con_type + ' ' + this.Con_size + ' ' + this.Con_stats" v-if="Con_stats === 'Loading'">
    <PhCircleNotch color="white">
      <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="3s" from="0 0 0" to="360 0 0"
                        repeatCount="indefinite"/>
    </PhCircleNotch>
  </button>
  <button :class="this.Con_type + ' ' + this.Con_size + ' ' + this.Con_stats" v-else v-html="this.Con_text">
  </button>
</template>
<style scoped>

button {
  margin: 10px;
  width: 100%;
  padding: 5px 15px;
  border-radius: 30px;
  border: none;
  color: #FFFFFF;
}

/* status iddle e hover */
.btn_primary_100_xl {
  background-color: #41B7FF;
}

.btn_primary_100_lg {
  background-color: #41B7FF;
}

.btn_primary_100_md {
  background-color: #41B7FF;
}

.btn_primary_100_sm {
  background-color: #41B7FF;
}

/* status pressed */
.btn_primary_150_xl {
  background-color: #3A95CD;
}

.btn_primary_150_lg {
  background-color: #3A95CD;
}

.btn_primary_150_md {
  background-color: #3A95CD;
}

.btn_primary_150_sm {
  background-color: #3A95CD;
}

/* status disabled */
.btn_grayscale_100_xl {
  background: #EEEEEE !important;
  color: #7E7F80 !important;
}

.btn_grayscale_100_lg {
  background: #EEEEEE !important;
  color: #7E7F80 !important;
}

.btn_grayscale_100_md {
  background: #EEEEEE !important;
  color: #7E7F80 !important;
}

.btn_grayscale_100_sm {
  background: #EEEEEE !important;
  color: #7E7F80 !important;
}

/* status left icon */

.btn_danger_color {
  background-color: #EE476F;
}

.btn_Hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #41B7FF !important;
}

.btn_Pressed {
  background: #3A95CD !important;
}

.btn_Disabled {
  background: #EEEEEE !important;
  color: #7E7F80 !important;
}

.btn_32 {
  height: 32px !important;
}

.btn_40 {
  height: 40px !important;
}

.btn_48 {
  height: 48px !important;
}

.btn_56 {
  height: 56px !important;
}
</style>


<script>

import {PhCircleNotch} from "phosphor-vue";

export default {
  components: {},
  directives: {
    PhCircleNotch
  },
  props: {
    size: {
      type: String,
      default: "btn_40"
    },
    type: {
      type: String,
      default: "btn_primary_color"
    },
    stats: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: "Criar conta"
    },
    icon_left: {
      type: String,
      default: null
    },
    icon_right: {
      type: String,
      default: null
    },
  },
  created() {

    if (this.Con_stats == 'Hover') {
      this.Con_stats = 'btn_Hover';
    }

    if (this.Con_stats == 'Pressed') {
      this.Con_stats = 'btn_Pressed';
    }

    if (this.Con_stats == 'Disabled') {
      this.Con_stats = 'btn_Disabled';
    }

    if (this.Con_stats == 'Loading') {
      this.Con_icon_left = null;
      this.Con_icon_right = null;
      //this.Con_text = '<ph-circle-notch />';
      this.Con_text = '<i class="ph-circle-notch"></i>';
    }

    if (this.Con_icon_left != null) {
      this.Con_text = '<img class="plus-icon pointer"  src="' + this.Con_icon_left + '" alt="plus-icon"> ' + '' + this.Con_text
    }

    if (this.Con_icon_right != null) {
      this.Con_text = this.Con_text + '<img class="plus-icon pointer" src="' + this.Con_icon_right + '" alt="plus-icon"> ';
    }


  },
  data() {
    return {
      Con_size: this.size,
      Con_type: this.type,
      Con_stats: this.stats,
      Con_text: this.text,
      Con_icon_left: this.icon_left,
      Con_icon_right: this.icon_right,
    }
  },
  computed() {

  }
}
</script>